<template>
    <div class="content">
        <div class="orderDetail">
            <div class="orderDetail_left">
                <img src="@/assets/image/check_filled.png" />
            </div>
            <div class="orderDetail_center">
                <div class="orderDetail_center_top">您的订单已支付成功，我们将尽快处理</div>
                <p>订单编号：{{order_list.sNo}}</p>
                <p>支付金额：<span>￥{{order_list.total}}</span></p>
                <p>支付方式：{{payStyle=='wallet_pay'?'余额支付':payStyle=='pc_wechat'?'微信支付':payStyle=='pc_alipay'?'支付宝支付':''}}</p>
                
                <div class="btnBox">
                    <div>查看订单</div>
                    <div @click="toDetail">继续逛逛</div>
                </div>
            </div>
        </div>

         <div class="recommend-con">
            <h3>为你推荐</h3>
            <div class="list-con">
                <div class="item" v-for="item in recommendList" :key="item.id" @click="todetail(item.id)">
                    <img :src="item.imgurl" class="good-img"/>
                    <div class="title">{{item.product_title}}</div>
                    <div class="bottom">
                        <span>¥{{item.price}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted,reactive,ref,toRefs} from 'vue'
import {getData} from '@/utils/req'
import {useRouter,useRoute} from 'vue-router'
export default {
    setup(){
        const router = useRouter()
        const route = useRoute()
        let recommendList = ref('')
        let pc_user = ref('')
        let state = reactive({
            order_list:'',
            payStyle:''
        })
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            getRecommend()
            state.order_list = JSON.parse(route.query.order_list)
            state.payStyle = route.query.payStyle
        })
        const getRecommend =()=>{
            const data ={
                module: 'app_pc',
                action: 'product',
                m: 'recommend',
                access_id:pc_user.value.access_id
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    recommendList.value = res.data
                }
            })
        }

        const todetail = (id)=>{
            const { href } = router.resolve({
                path: '/homedetail',
                query: {
                    good_id:id
                }
            });
            window.open(href, "_blank");
        }

        const toDetail = () =>{
            router.push({
                path:'/'
            })
        }
        return {
            recommendList,
            ...toRefs(state),
            todetail,
            toDetail
        }
    }
}
</script>
<style scoped lang="scss">
    .content{
        width: 1200px;
        margin: 0 auto 0;
        .orderDetail{
            display: flex;
            padding: 40px;
            background-color: #F6F6F6;
            margin-top: 30px;
            .orderDetail_left{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: #59BE3C;
                margin-right: 10px;
            }
            .orderDetail_center{
                .orderDetail_center_top{
                    font-size: 18px;
                    line-height: 28px;
                    color: #020202;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 14px;
                    line-height: 21px;
                    color: #020202;
                }
                .btnBox{
                    display: flex;
                    margin-top: 36px;
                    div:first-child{
                        background-color: #D4282D;
                        color: #FFFFFF;
                    }
                    div{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 14px;
                        height: 36px;
                        margin-right: 10px;
                        box-sizing: border-box;
                        font-size: 14px;
                        line-height: 14px;
                        text-decoration: none;
                    }
                    div:last-child{
                        border: 1px solid #DDDDDD;
                        color: #666666;
                        background-color: #FFFFFF;
                    }
                }
            }
        }
         .recommend-con{
            width: 100%;
            margin-top: 70px;
            margin-bottom: 70px;
            h3{
                color: #020202;
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
            }
            .list-con{
                display: flex;
                align-items: center;
                margin-top: 15px;
                .item{
                    text-align: center;
                    width: 288px;
                    border:1px solid #eee;
                    cursor: pointer;
                    .good-img{
                        width: 100%;
                    }
                    .title{
                        font-weight: bold;
                        color: #020202;
                        font-size: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-top: 30px;
                        margin-bottom: 10px;
                    }
                    .bottom{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 30px;
                        span{
                            color: #D4282D;
                            font-size: 14px;
                            vertical-align: middle;
                        }
                        .cart{
                            width: 24px;
                            height: 24px;
                            background: #EEEEEE;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 5px;
                            .cart-img{
                                width: 16px;
                                
                            }
                        }
                    }
                }
                .item:not(:last-child){
                    margin-right: auto;
                }
                .item:hover{
                    background: #eee;
                }
            }
        }
    }
</style>